<template>
  <svg width="155" height="24" viewBox="0 0 155 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="154" height="23" rx="11.5" stroke="black"/>
    <path d="M18.8509 14.67H16.0709L15.6109 16H14.1409L16.6509 9.01H18.2809L20.7909 16H19.3109L18.8509 14.67ZM18.4709 13.55L17.4609 10.63L16.4509 13.55H18.4709ZM26.9591 10.46V16H25.5491V15.3C25.3691 15.54 25.1324 15.73 24.8391 15.87C24.5524 16.0033 24.2391 16.07 23.8991 16.07C23.4657 16.07 23.0824 15.98 22.7491 15.8C22.4157 15.6133 22.1524 15.3433 21.9591 14.99C21.7724 14.63 21.6791 14.2033 21.6791 13.71V10.46H23.0791V13.51C23.0791 13.95 23.1891 14.29 23.4091 14.53C23.6291 14.7633 23.9291 14.88 24.3091 14.88C24.6957 14.88 24.9991 14.7633 25.2191 14.53C25.4391 14.29 25.5491 13.95 25.5491 13.51V10.46H26.9591ZM29.9704 11.61V14.29C29.9704 14.4767 30.0137 14.6133 30.1004 14.7C30.1937 14.78 30.3471 14.82 30.5604 14.82H31.2104V16H30.3304C29.1504 16 28.5604 15.4267 28.5604 14.28V11.61H27.9004V10.46H28.5604V9.09H29.9704V10.46H31.2104V11.61H29.9704ZM34.6773 16.09C34.144 16.09 33.664 15.9733 33.2373 15.74C32.8107 15.5 32.474 15.1633 32.2273 14.73C31.9873 14.2967 31.8673 13.7967 31.8673 13.23C31.8673 12.6633 31.9907 12.1633 32.2373 11.73C32.4907 11.2967 32.834 10.9633 33.2673 10.73C33.7007 10.49 34.184 10.37 34.7173 10.37C35.2507 10.37 35.734 10.49 36.1673 10.73C36.6007 10.9633 36.9407 11.2967 37.1873 11.73C37.4407 12.1633 37.5673 12.6633 37.5673 13.23C37.5673 13.7967 37.4373 14.2967 37.1773 14.73C36.924 15.1633 36.5773 15.5 36.1373 15.74C35.704 15.9733 35.2173 16.09 34.6773 16.09ZM34.6773 14.87C34.9307 14.87 35.1673 14.81 35.3873 14.69C35.614 14.5633 35.794 14.3767 35.9273 14.13C36.0607 13.8833 36.1273 13.5833 36.1273 13.23C36.1273 12.7033 35.9873 12.3 35.7073 12.02C35.434 11.7333 35.0973 11.59 34.6973 11.59C34.2973 11.59 33.9607 11.7333 33.6873 12.02C33.4207 12.3 33.2873 12.7033 33.2873 13.23C33.2873 13.7567 33.4173 14.1633 33.6773 14.45C33.944 14.73 34.2773 14.87 34.6773 14.87Z" fill="#2F2F2F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M138.227 15L144.589 10H131.865L138.227 15Z" :fill="fillColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: "#2F2F2F",
    },
    value: {
      type: String,
      default: "0",
    },
  },
};
</script>
<style lang="scss" scope></style>
