<template>
  <div id="tabs" class="col-span-9 pl-5">
    <div class="w-full tabs-menu grid grid-cols-4 gap-5 text-left pt-5">
      <a
        class="cursor-pointer text-14 font-extrabold"
        :class="{ active: currentTab == 'product-info' }"
        @click="showTab('product-info')"
        ><span>PRODUCT INFO</span></a
      >
      <a
        class="cursor-pointer text-14 font-extrabold"
        :class="{ active: currentTab == 'notification-history' }"
        @click="showTab('notification-history')"
        ><span>NOTIFICATION HISTORY</span></a
      >
    </div>
    <div v-show="currentTab == 'product-info'" class="product-info w-full grid grid-cols-3 mt-10">
      <div class="col-span-1">
        <h4>Appliance Type</h4>
        <p>Duct-Free Split</p>
        <h4>Nickname</h4>
        <p>DFS</p>
      </div>
      <div class="col-span-1">
        <h4>Model Number</h4>
        <p>CFE28UP2MCS1</p>
        <h4>Serial Number</h4>
        <p>HM500560</p>
        <h4>Software Version</h4>
        <p>1.0.1</p>
      </div>
      <div class="col-span-1">
        <h4>WI-FI Module<br />Software Version</h4>
        <p>1.0.4</p>
        <h4>Install Date</h4>
        <p>XX/XX/XXXX</p>
        <h4>Warranty Expiration</h4>
        <p>XX/XX/XXXX</p>
      </div>
    </div>
    <div v-show="currentTab == 'notification-history'" class="w-full">
      <table class="table-auto w-full mt-10">
        <thead>
          <tr>
            <th class="text-left font-extrabold">ISSUE</th>
            <th class="text-left font-extrabold">TIME</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ductless System Offline</td>
            <td>3:08 AM</td>
          </tr>
          <tr>
            <td>Clean Filter Reminder</td>
            <td>2:38 AM</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTab: 'product-info',
    };
  },
  methods: {
    showTab: function(newTab) {
      this.currentTab = newTab;
    },
  },
};
</script>
<style lang="scss" scoped>
.panel {
  line-height: 1em;
}
.arrow {
  cursor: pointer;
}
.set {
  a {
    border-radius: 14px;
    height: 41px;
  }
}
.border-bottom {
  border-bottom: 1px solid #d0d0d0;
}
#tabs{
    .pt-10{
        padding-top:110px;
    }
    .operating{
        img{
            top:75px;
        }
    }
}
</style>
