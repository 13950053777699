<template>
  <div class="panel col-span-3 rounded px-5 py-4">
    <div class="w-full grid grid-cols-2">
      <div class="font-extrabold">Duct-Free Split</div>
      <div class="font-extrabold online-green text-right text-sm">Online</div>
    </div>
    <div class="w-full grid grid-cols-2 pt-8 pb-3">
      <div><img src="@/assets/img/ductFreeSplit_icon.svg" class="float-left" /></div>
      <div><img src="@/assets/img/radiobutton_power.svg" class="float-right" /></div>
    </div>
    <hr class="mt-5 mb-3" />
    <div v-show="!showToggle" class="mb-3">
      <div class="w-full grid grid-cols-2 pb-4 border-bottom">
        <div class="text-blak font-extrabold text-xs align-middle m-auto mx-0">ENABLE PROPERTY SETTING OVERRIDE</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_power_green.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 py-4 border-bottom">
        <div class="text-black font-extrabold text-xs align-middle m-auto mx-0">MAX HEAT TEMP</div>
        <div class="ml-auto mr-0"><temp-control value="75" /></div>
      </div>
      <div class="w-full grid grid-cols-2 py-4 border-bottom">
        <div class="text-black font-extrabold text-xs align-middle m-auto mx-0">MAX COOL TEMP</div>
        <div class="ml-auto mr-0"><temp-control value="66" /></div>
      </div>
      <div class="w-full grid grid-cols-2 py-4 border-bottom">
        <div class="text-black font-extrabold text-xs align-middle m-auto mx-0">SET TEMP</div>
        <div class="ml-auto mr-0"><temp-control value="72" /></div>
      </div>
      <div class="w-full grid grid-cols-2 py-4 border-bottom">
        <div class="text-black font-extrabold text-xs align-middle m-auto mx-0">FAN MODE</div>
        <div class="ml-auto mr-0"><dropdown-control value="auto" /></div>
      </div>
    </div>
    <div class="w-full grid grid-cols-2 pb-2">
      <div class="font-extrabold text-grey text-sm align-middle m-auto mx-0">AUX SETTINGS</div>
      <div>
        <img
          v-show="showToggle"
          src="@/assets/img/arrowToggle.svg"
          class="arrow relative float-right"
          @click="toggle"
        />
        <img
          v-show="!showToggle"
          src="@/assets/img/arrowToggle_closed.svg"
          class="arrow relative float-right"
          @click="toggle"
        />
      </div>
    </div>
    <div v-show="showToggle">
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">OPERATING</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/dropdown_mode.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">MAKEUPPAIR SPEED</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/plusminus.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">MINIMUM SAFE</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">MAXIMUM SAFE</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">CONTINUOUS</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">EXTERNAL</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">DUCTED</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
    </div>
    <div class="w-full py-4 px-10 set text-center align-middle m-auto block">
      <a class="w-full rounded text-white m-auto text-center bg-black block"
        ><span class="block px-10 pt-3 font-extrabold">Set</span></a
      >
    </div>
    <div class="w-full grid grid-cols-2 pb-2">
      <router-link to="/assign-task" class="text-black text-xs align-middle m-auto mx-0 font-bold">Assign Task ></router-link>
      <div class="text-black text-xs align-middle m-auto mx-0 text-right font-bold">
        Remove >
      </div>
    </div>
  </div>
</template>

<script>
import { DropdownControl, TempControl } from '../icons/';
export default {
  components: { DropdownControl, TempControl },
  data() {
    return {
      showToggle: false,
    };
  },
  methods: {
    toggle: function() {
      this.showToggle = !this.showToggle;
    },
  },
};
</script>
<style lang="scss" scoped>
.panel {
  line-height: 1em;
}
.arrow {
  cursor: pointer;
}
.set {
  a {
    border-radius: 14px;
    height: 41px;
  }
}
.border-bottom{
    border-bottom:1px solid #D0D0D0;
}
</style>
