<template>
  <svg width="97" height="22" viewBox="0 0 97 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M89.532 11.926H86.914V14.586H85.01V11.926H82.392V10.12H85.01V7.446H86.914V10.12H89.532V11.926Z" fill="#1C1C1C"/>
    <circle cx="86" cy="11" r="10" stroke="#979797"/>
    <text class="font-extrabold text-right text-sm" x="35" y="17" fill="#000000">{{value}}°</text>
    <path d="M13.922 10.19V11.828H7.972V10.19H13.922Z" fill="#1C1C1C"/>
    <circle cx="11" cy="11" r="10" stroke="#979797"/>
</svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: "#54544F",
    },
    value: {
      type: String,
      default: "0",
    },
  },
};
</script>
<style lang="scss" scope></style>
